// @flow
import axios from 'axios'

// $FlowFixMe
import config from '@/agencies-config'
import { modelMapper, ModelTypes } from '@/models'
import { Enums, PDFKeys } from '@/constants'
import { camelObjectToSnakeObject } from '@/helpers'
import StorageService from '@/helpers/StorageService'

const ACCEPT_USER_INVITE = 'accounts/accept-invite/'
const RESEND_USER_INVITE = 'accounts/resend-invite/'
const USER_DATA_URL = 'accounts/user-data/'
const LOGIN_USER_URL = 'accounts/login/'
const LOGOUT_USER_URL = 'accounts/logout/'
const FORGOT_PASSWORD_URL = 'accounts/forgot-password/'
const RESET_PASSWORD_URL = 'accounts/reset-password/'
const LOCK_ACCOUNT_URL = 'accounts/lock-account/'
const PORTAL_SET_PASSWORD_URL = 'accounts/change-password/'
const ME_URL = 'accounts/me/'
const CLIENTS_URL = 'accounts/clients/'
const ANNUITY_POLICIES_URL = 'annuities/'
const ANNUITY_POLICIES_SHORT_URL = 'annuities/short/'
const INSURANCE_POLICIES_URL = 'insurance-policies/'
const INSURANCE_POLICIES_SHORT_URL = 'insurance-policies/short/'
const INSURANCE_POLICIES_CALENDAR_URL = 'insurance-policies/calendar/'
const INSURANCE_PRIVATE_PLACEMENTS_URL = 'private-placements/calendar/'
const INSURANCE_VUL_STATEMENTS_URL = 'vuls/calendar/'
const PRIVATE_PLACEMENTS_URL = 'private-placements/'
const PRIVATE_PLACEMENTS_SHORT_URL = 'private-placements/short/'
const IDF_STATEMENTS_URL = 'idf-statements/'
const PPA_STATEMENTS_URL = 'ppa-statements/'
const PPA_STATEMENTS_SHORT_URL = 'ppa-statements/short/'
const PPLI_STATEMENTS_URL = 'ppli-statements/'
const PPLI_STATEMENTS_SHORT_URL = 'ppli-statements/short/'
const VUL_STATEMENTS_URL = 'vul-statements/'
const VUL_STATEMENTS_SHORT_URL = 'vul-statements/short/'
const VUL_POLICIES_URL = 'vuls/'
const VUL_POLICIES_SHORT_URL = 'vuls/short/'
const DISABILITY_POLICIES_SHORT_URL = 'disability-policies/short/'
const DISABILITY_POLICIES_URL = 'disability-policies/'
const IDF_AVAILABILITY_PDF_URL = 'idf-availability-pdf/'
const FOOTNOTES_URL = 'footnotes/'
const GENERATE_PDF = 'print-pdf/generate/'
const CHECK_PDF_RESULT = 'print-pdf/result/'
const PPA_XIRR_RATES_URL = 'annuities/xirr-rates/'
const PPLI_XIRR_RATES_URL = 'private-placements/xirr-rates/'
const VUL_XIRR_RATES_URL = 'vuls/xirr-rates/'
const PPA_XIRR_RATES_IDF_URL = 'annuities/xirr-rates-idf/'
const PPLI_XIRR_RATES_IDF_URL = 'private-placements/xirr-rates-idf/'
const VUL_XIRR_RATES_IDF_URL = 'vuls/xirr-rates-idf/'
const IUL_MONTHLY_STATEMENTS_URL = 'iul-monthly-statements/'
const IUL_ANNUAL_STATEMENTS_URL = 'iul-annual-statements/'
const INDEX_ACCOUNT_RATES_URL = 'index-account-rates/'
const GENERAL_DOCUMENTS_URL = 'general-documents/'

const SESSIONS_STATUSES_URL = 'sessions/statuses/'
const CURRENT_USER_SESSIONS_URL = 'sessions/'
const END_SESSIONS_URL = 'sessions/end_sessions/'

class ApiService {
  axios: any

  authToken: { token?: string } | null

  get activeClientHeader() {
    return this.axios.defaults.headers.ActiveClient
  }

  constructor() {
    const authTokens = StorageService.getAuth() || {}
    this.setupInstance(authTokens.token)
  }

  setupInstance = (authToken: { token: string } = { token: '' }, activeClient: string = '') => {
    const data: {
      headers: {
        Accept: string,
        Authorization?: string,
        ActiveClient?: string,
      },
      baseURL: string,
      timeout: number,
    } = {
      baseURL: config.apiURL,
      timeout: 200000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    this.authToken = authToken
    if (authToken?.token?.length > 0) {
      data.headers.Authorization = `Token ${authToken.token}`
    }
    if (activeClient?.length) {
      data.headers.ActiveClient = `${activeClient}`
    }
    this.axios = axios.create(data)
    this.axios.interceptors.response.use(
      response => {
        const { apiResponseType } = response.config
        if (apiResponseType) {
          response.data = modelMapper(
            apiResponseType,
            // $FlowFixMe
            response.data?.data || response.data?.results || response.data,
          )
        }
        return response
      },
      async error => {
        if (error.response?.status === 429) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({
            response: { status: 429, data: { error: 'Too many unsuccessful attempts' } },
          })
        }
        return Promise.reject(error)
      },
    )
  }

  clearAuthToken = () => {
    this.authToken = null
    this.axios.defaults.headers.Authorization = ''
  }

  setAuthHeader = (token: string) => {
    this.axios.defaults.headers.Authorization = `Token ${token}`
  }

  setActiveClientHeader = (id: string) => {
    this.axios.defaults.headers.ActiveClient = id
  }

  acceptInvite = (data: AcceptInvitePayload) =>
    this.axios.post<*, { data: Object }>(ACCEPT_USER_INVITE, data, {
      apiResponseType: ModelTypes.LoginResponse,
    })

  resendInvite = (data: ResendInvitePayload) =>
    this.axios.post<*, { data: Object }>(RESEND_USER_INVITE, data)

  loginUser = (data: LoginPayloadType) =>
    this.axios.post<*, { data: Object }>(LOGIN_USER_URL, data, {
      apiResponseType: ModelTypes.LoginResponse,
    })

  logoutUser = () => this.axios.post<*, *>(LOGOUT_USER_URL)

  verifyDevice = (data: VerifyDevicePayload) =>
    this.axios.post<*, { data: Object }>(LOGIN_USER_URL, data, {
      apiResponseType: ModelTypes.LoginResponse,
    })

  resendVerifyCode = (data: ResendVerificationPayload) =>
    this.axios.post<*, { data: Object }>(LOGIN_USER_URL, data)

  forgotPassword = (data: ForgotPasswordPayload) =>
    this.axios.post<*, { data: Object }>(FORGOT_PASSWORD_URL, data)

  resetPassword = (data: ResetPasswordPayload) =>
    this.axios.post<*, { data: Object }>(RESET_PASSWORD_URL, data)

  lockAccount = (data: LockAccountPayload) =>
    this.axios.post<*, { data: Object }>(LOCK_ACCOUNT_URL, data)

  portalSetEmail = (data: PortalUpdateUserInfoPayload) =>
    this.axios.post<*, { data: Object }>(ME_URL, data, {
      apiResponseType: ModelTypes.UserInfoResponse,
    })

  portalSetPassword = (data: PortalSetPasswordPayload) =>
    this.axios.post<*, { data: Object }>(PORTAL_SET_PASSWORD_URL, data)

  portalSetUsername = (data: PortalUpdateUserInfoPayload) =>
    this.axios.post<*, { data: Object }>(ME_URL, data, {
      apiResponseType: ModelTypes.UserInfoResponse,
    })

  portalSetMobile = (data: PortalUpdateUserInfoPayload) =>
    this.axios.post<*, { data: Object }>(ME_URL, data, {
      apiResponseType: ModelTypes.UserInfoResponse,
    })

  getInsurancePolicies = () =>
    this.axios.get<*, { data: Object }>(INSURANCE_POLICIES_URL, {
      apiResponseType: ModelTypes.InsurancePolicies,
    })

  getInsurancePolicyById = id =>
    this.axios.get<*, { data: Object }>(`${INSURANCE_POLICIES_URL}${id}/`, {
      apiResponseType: ModelTypes.InsurancePolicyDetails,
    })

  getInsurancePoliciesShort = () =>
    this.axios.get<*, { data: Object }>(INSURANCE_POLICIES_SHORT_URL, {
      apiResponseType: ModelTypes.InsurancePoliciesShort,
    })

  getInsurancePoliciesCalendar = () =>
    this.axios.get<*, { data: Object }>(INSURANCE_POLICIES_CALENDAR_URL, {
      apiResponseType: ModelTypes.InsurancePoliciesCalendar,
    })

  getPrivatePlacementsCalendar = () =>
    this.axios.get<*, { data: Object }>(INSURANCE_PRIVATE_PLACEMENTS_URL, {
      apiResponseType: ModelTypes.PrivatePlacementsCalendar,
    })

  getVULStatementsCalendar = () =>
    this.axios.get<*, { data: Object }>(INSURANCE_VUL_STATEMENTS_URL, {
      apiResponseType: ModelTypes.VULPoliciesCalendar,
    })

  getPrivatePlacements = () =>
    this.axios.get<*, { data: Object }>(PRIVATE_PLACEMENTS_URL, {
      apiResponseType: ModelTypes.PrivatePlacements,
    })

  getPrivatePlacementById = (id: number) =>
    this.axios.get<*, { data: Object }>(`${PRIVATE_PLACEMENTS_URL}${id}/`, {
      apiResponseType: ModelTypes.PrivatePlacementDetails,
    })

  getPrivatePlacementsShort = () =>
    this.axios.get<*, { data: Object }>(PRIVATE_PLACEMENTS_SHORT_URL, {
      apiResponseType: ModelTypes.PrivatePlacementsShort,
    })

  getAnnuityPolicies = () =>
    this.axios.get<*, { data: Object }>(ANNUITY_POLICIES_URL, {
      apiResponseType: ModelTypes.Annuities,
    })

  getAnnuityPolicyById = (id: number) =>
    this.axios.get<*, { data: Object }>(`${ANNUITY_POLICIES_URL}${id}/`, {
      apiResponseType: ModelTypes.AnnuityDetails,
    })

  getAnnuityPoliciesShort = () =>
    this.axios.get<*, { data: Object }>(ANNUITY_POLICIES_SHORT_URL, {
      apiResponseType: ModelTypes.AnnuitiesShort,
    })

  getDisabilityPoliciesShort = () =>
    this.axios.get<*, { data: Object }>(DISABILITY_POLICIES_SHORT_URL, {
      apiResponseType: ModelTypes.DisabilityPolicies,
    })

  getDisabilityPolicyById = (id: number) =>
    this.axios.get<*, { data: Object }>(`${DISABILITY_POLICIES_URL}${id}/`, {
      apiResponseType: ModelTypes.DisabilityDetails,
    })

  me = () =>
    this.axios.get<*, { data: Object }>(ME_URL, {
      apiResponseType: ModelTypes.UserInfoResponse,
    })

  getClients = () =>
    this.axios.get<*, { data: Object }>(CLIENTS_URL, {
      apiResponseType: ModelTypes.ClientsResponse,
    })

  getIDFStatements = () =>
    this.axios.get<*, { data: Object }>(IDF_STATEMENTS_URL, {
      apiResponseType: ModelTypes.IDFStatements,
    })

  getPPAStatements = () =>
    this.axios.get<*, { data: Object }>(PPA_STATEMENTS_URL, {
      apiResponseType: ModelTypes.PPAStatements,
    })

  getPPAStatementsShort = () =>
    this.axios.get<*, { data: Object }>(PPA_STATEMENTS_SHORT_URL, {
      apiResponseType: ModelTypes.PPAStatements,
    })

  getPPLIStatements = () =>
    this.axios.get<*, { data: Object }>(PPLI_STATEMENTS_URL, {
      apiResponseType: ModelTypes.PPLIStatements,
    })

  getPPLIStatementsShort = () =>
    this.axios.get<*, { data: Object }>(PPLI_STATEMENTS_SHORT_URL, {
      apiResponseType: ModelTypes.PPLIStatements,
    })

  getVULStatements = () =>
    this.axios.get<*, { data: Object }>(VUL_STATEMENTS_URL, {
      apiResponseType: ModelTypes.VULStatements,
    })

  getVULStatementsShort = () =>
    this.axios.get<*, { data: Object }>(VUL_STATEMENTS_SHORT_URL, {
      apiResponseType: ModelTypes.VULStatements,
    })

  getVULPolicies = () =>
    this.axios.get<*, { data: Object }>(VUL_POLICIES_URL, {
      apiResponseType: ModelTypes.VULPolicies,
    })

  getVULPolicyById = (id: number) =>
    this.axios.get<*, { data: Object }>(`${VUL_POLICIES_URL}${id}/`, {
      apiResponseType: ModelTypes.VULPolicyDetails,
    })

  getVULPoliciesShort = () =>
    this.axios.get<*, { data: Object }>(VUL_POLICIES_SHORT_URL, {
      apiResponseType: ModelTypes.VULPoliciesShort,
    })

  getIDFPdfAvailability = () =>
    this.axios.get<*, { data: Object }>(IDF_AVAILABILITY_PDF_URL, {
      apiResponseType: ModelTypes.IDFImage,
    })

  getFootnotes = () =>
    this.axios.get<*, { data: Object }>(FOOTNOTES_URL, {
      apiResponseType: ModelTypes.FootnoteGroup,
    })

  generatePDF = (data: PrintPayload) => {
    const parsedData = camelObjectToSnakeObject(data)

    return this.axios.post<*, { data: Object }>(GENERATE_PDF, parsedData)
  }

  generateDetailedPDF = ({ policyId, policyType, ...data }: PrintDetailedPayload) => {
    const parsedData = camelObjectToSnakeObject(data)

    const { pdfKey } = data

    let url = ''

    switch (pdfKey) {
      case PDFKeys.ppaPolicyDetail:
        url = ANNUITY_POLICIES_URL
        break
      case PDFKeys.vulPolicyDetail:
        url = VUL_POLICIES_URL
        break
      case PDFKeys.disabilityPolicyDetail:
        url = DISABILITY_POLICIES_URL
        break
      default:
        if (policyType === Enums.PolicyType.PRIVATE_PLACEMENT_LIFE) {
          url = PRIVATE_PLACEMENTS_URL
        } else {
          url = INSURANCE_POLICIES_URL
        }
    }

    return this.axios.post<*, { data: Object }>(`${url}${policyId}/generate-pdf/`, parsedData)
  }

  generateConsolidatedPDF = ({ pdfKey, date, ...data }: PrintConsolidatedPayload) => {
    const parsedData = camelObjectToSnakeObject(data)

    let url = ''

    switch (pdfKey) {
      case PDFKeys.ppaStatementDetail:
        url = PPA_STATEMENTS_URL
        break
      case PDFKeys.vulStatementDetail:
        url = VUL_STATEMENTS_URL
        break
      default:
        url = PPLI_STATEMENTS_URL
    }

    return this.axios.post<*, { data: Object }>(`${url}generate-pdf/${date}/`, parsedData)
  }

  fetchUserData = (token: string) =>
    this.axios.get<*, { data: Object }>(USER_DATA_URL, { params: { token } })

  checkPDFResult = (task: string): Promise<{ data: Object }> =>
    new Promise((resolve: (value: { data: Object }) => void, error: (reason: any) => void) => {
      // If the request fails, retry again once
      this.axios
        .get<*, { data: Object }>(CHECK_PDF_RESULT, { params: { task } })
        .then(resolve)
        .catch(() => {
          this.axios
            .get<*, { data: Object }>(CHECK_PDF_RESULT, { params: { task } })
            .then(resolve)
            .catch(error)
        })
    })

  getXIRRRates = ({ type, date, ids }: XIRRPayload) => {
    const queryParam = ids ? ids.map(id => `id=${id}`).join('&') : ''

    switch (type) {
      case 'PPA':
        return this.axios.get<*, { data: Object }>(`${PPA_XIRR_RATES_URL}${date}/?${queryParam}`, {
          apiResponseType: ModelTypes.XIRRRate,
        })
      case 'PPLI':
        return this.axios.get<*, { data: Object }>(`${PPLI_XIRR_RATES_URL}${date}/?${queryParam}`, {
          apiResponseType: ModelTypes.XIRRRate,
        })
      case 'VUL':
        return this.axios.get<*, { data: Object }>(`${VUL_XIRR_RATES_URL}${date}/?${queryParam}`, {
          apiResponseType: ModelTypes.XIRRRate,
        })
      default:
        return null
    }
  }

  getXIRRRatesIDF = ({ type, date, ids }: XIRRPayload) => {
    const queryParam = ids ? ids.map(id => `id=${id}`).join('&') : ''

    switch (type) {
      case 'PPA':
        return this.axios.get<*, { data: Object }>(
          `${PPA_XIRR_RATES_IDF_URL}${date}/?${queryParam}`,
          {
            apiResponseType: ModelTypes.XIRRRateIDF,
          },
        )
      case 'PPLI':
        return this.axios.get<*, { data: Object }>(
          `${PPLI_XIRR_RATES_IDF_URL}${date}/?${queryParam}`,
          {
            apiResponseType: ModelTypes.XIRRRateIDF,
          },
        )
      case 'VUL':
        return this.axios.get<*, { data: Object }>(
          `${VUL_XIRR_RATES_IDF_URL}${date}/?${queryParam}`,
          {
            apiResponseType: ModelTypes.XIRRRateIDF,
          },
        )
      default:
        return null
    }
  }

  getIULMonthlyStatements = () =>
    this.axios.get<*, { data: Object }>(IUL_MONTHLY_STATEMENTS_URL, {
      apiResponseType: ModelTypes.IULMonthlyStatement,
    })

  getIULAnnualStatements = () =>
    this.axios.get<*, { data: Object }>(IUL_ANNUAL_STATEMENTS_URL, {
      apiResponseType: ModelTypes.IULAnnualStatement,
    })

  getIndexAccountRates = () =>
    this.axios.get<*, { data: Object }>(INDEX_ACCOUNT_RATES_URL, {
      apiResponseType: ModelTypes.IndexAccountRates,
    })

  getGeneralDocuments = () =>
    this.axios.get<*, { data: Object }>(GENERAL_DOCUMENTS_URL, {
      apiResponseType: ModelTypes.GeneralDocuments,
    })

  getSessionsStatuses = (data: Array<string>) =>
    this.axios.post<*, { data: Array<any> }>(SESSIONS_STATUSES_URL, data, {
      apiResponseType: ModelTypes.SessionsStatuses,
    })

  getCurrentUserSessions = () =>
    this.axios.get<*, { data: Object }>(CURRENT_USER_SESSIONS_URL, {
      apiResponseType: ModelTypes.SessionsStatuses,
    })

  endSessions = (data: Array<number>) =>
    this.axios.post<*, *>(END_SESSIONS_URL, { session_ids: data })
}

export default new ApiService()
