// @flow
import { call, put } from 'redux-saga/effects'
import { ActionCreators } from '@/redux'
import {
  getInsurancePoliciesPayload,
  getAnnuitiesPayload,
  getFootnotesPayload,
  getIDFStatementsPayload,
  getPPAStatementsPayload,
  getPPAStatementsShortPayload,
  getPPLIStatementsPayload,
  getPPLIStatementsShortPayload,
  getVULStatementsPayload,
  getVULPoliciesPayload,
  getVULStatementsShortPayload,
  getPrivatePlacementsPayload,
  getPrivatePlacementsShortPayload,
  getVULPoliciesShortPayload,
  getInsurancePoliciesShortPayload,
  getIDFPdfAvailabilityPayload,
  checkPDFResultPayload,
  getIULMonthlyStatementsPayload,
  getIULAnnualStatementsPayload,
  getIndexAccountRatesPayload,
  getGeneralDocumentsPayload,
  getAnnuitiesShortPayload,
  getDisabilityPoliciesShortPayload,
} from '@/redux/api-payloads'
import { MetaKeys } from '@/redux/api'
import { _initiateDownload } from '@/helpers'
import { Strings } from '@/constants'

export function* fetchAnnuities(): GeneratorType {
  const payload = getAnnuitiesPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchAnnuitiesShort(): GeneratorType {
  const payload = getAnnuitiesShortPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchDisabilityPoliciesShort(): GeneratorType {
  const payload = getDisabilityPoliciesShortPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchFootnotes(): GeneratorType {
  const payload = getFootnotesPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchIDFStatements(): GeneratorType {
  const payload = getIDFStatementsPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchInsurancePolicies(): GeneratorType {
  const payload = getInsurancePoliciesPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchInsurancePoliciesShort(): GeneratorType {
  const payload = getInsurancePoliciesShortPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchPrivatePlacements(): GeneratorType {
  const payload = getPrivatePlacementsPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchPrivatePlacementsShort(): GeneratorType {
  const payload = getPrivatePlacementsShortPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchPPPLIStatements(): GeneratorType {
  const payload = getPPLIStatementsPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchPPPLIStatementsShort(): GeneratorType {
  const payload = getPPLIStatementsShortPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchVULStatements(): GeneratorType {
  const payload = getVULStatementsPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchVULStatementsShort(): GeneratorType {
  const payload = getVULStatementsShortPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchVULPolicies(): GeneratorType {
  const payload = getVULPoliciesPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchVULPoliciesShort(): GeneratorType {
  const payload = getVULPoliciesShortPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchPPAStatements(): GeneratorType {
  const payload = getPPAStatementsPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchPPAStatementsShort(): GeneratorType {
  const payload = getPPAStatementsShortPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchIDFPdfAvailability(): GeneratorType {
  const payload = getIDFPdfAvailabilityPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* checkPDFResult(action: {
  type: string,
  payload: { task: string, _meta: { id: string } },
}): GeneratorType {
  const { task, _meta } = action.payload
  // The polling request needs this meta data to poll.
  yield put(ActionCreators.api.updateMeta.dispatch({ key: MetaKeys.checkPDFResultMeta, _meta }))
  const payload = checkPDFResultPayload(task, _meta)
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* checkPDFResultError(): GeneratorType {
  yield call(alert, Strings.pdfError)
}

export function* checkPDFResultSuccess(action: {
  type: string,
  payload: { name: string, url: string, _meta: { id: string } },
}): GeneratorType {
  const { name, url } = action.payload
  yield call(_initiateDownload, name, url)
}

export function* fetchIULMonthlyStatements(): GeneratorType {
  const payload = getIULMonthlyStatementsPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchIULAnnualStatements(): GeneratorType {
  const payload = getIULAnnualStatementsPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}

export function* fetchIndexAccountRates(): GeneratorType {
  const payload = getIndexAccountRatesPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}
export function* fetchGeneralDocuments(): GeneratorType {
  const payload = getGeneralDocumentsPayload()
  yield put(ActionCreators.api.makeRequest.dispatch(payload))
}
