// @flow
import { cloneDeep } from 'lodash'
import moment from 'moment'
import type Moment from 'moment'
import { Enums } from '@/constants'
import {
  Annuity,
  InsurancePolicy,
  PPAStatement,
  PPLIStatement,
  VULPolicy,
  VULStatement,
  PrivatePlacement,
  PolicyDocument,
  XIRRRate,
  XIRRRateIDF,
  AnnuityShort,
  Disability,
  InsurancePolicyShort,
  PrivatePlacementShort,
  VULPolicyShort,
  InsurancePolicyCalendar,
  PrivatePlacementCalendar,
  VULPolicyCalendar,
} from '@/models'
import api from '@/selectors/api'
import { PendingKeys } from '@/redux/api'

const getPolicyStore = (store: StoreState): PolicyStoreState => store.policy

const getIDFStatements = (store: StoreState) => getPolicyStore(store).idfStatements

const getGeneralDocuments = (store: StoreState) => getPolicyStore(store).generalDocuments

const getPolicyByPolicyTypeAndPolicyId = (
  store: StoreState,
  policyId: ?string,
  policyType: InsurancePolicy.modelName | PrivatePlacement.modelName | VULPolicy.modelName,
): ?InsurancePolicy | ?PrivatePlacement | ?VULPolicy => {
  if (!policyId) return null

  const { insurancePolicies, privatePlacements, vulPolicies } = getPolicyStore(store)
  if (policyType === InsurancePolicy.modelName) {
    return insurancePolicies[policyId]
  }
  if (policyType === PrivatePlacement.modelName) {
    return privatePlacements[policyId]
  }
  if (policyType === VULPolicy.modelName) {
    return vulPolicies[policyId]
  }
  return null
}

const getInsurancePolicies = (store: StoreState): { [string]: InsurancePolicy } =>
  getPolicyStore(store).insurancePolicies

const getAnnuityPolicies = (store: StoreState): { [string]: Annuity } =>
  getPolicyStore(store).annuities

const getAnnuityPoliciesShort = (store: StoreState): { [string]: AnnuityShort } =>
  getPolicyStore(store).annuitiesShort

const getDisabilityPoliciesShort = (store: StoreState): { [string]: Disability } =>
  getPolicyStore(store).disabilityPoliciesShort

const getInsurancePoliciesShort = (store: StoreState): { [string]: InsurancePolicy } =>
  getPolicyStore(store).insurancePoliciesShort

const getInsurancePoliciesCalendar = (store: StoreState): Array<InsurancePolicyCalendar> =>
  getPolicyStore(store).insurancePoliciesCalendar

const getPrivatePlacementsCalendar = (store: StoreState): Array<PrivatePlacementCalendar> =>
  getPolicyStore(store).privatePlacementsCalendar

const getVULStatementsCalendar = (store: StoreState): Array<VULPolicyCalendar> =>
  getPolicyStore(store).vulStatementsCalendar

const getInsurancePoliciesCalendarLoading = (store: StoreState): Boolean =>
  getPolicyStore(store).insurancePoliciesCalendarLoading

const getPrivatePlacementsCalendarLoading = (store: StoreState): Boolean =>
  getPolicyStore(store).privatePlacementsCalendarLoading

const getVULStatementsCalendarLoading = (store: StoreState): Boolean =>
  getPolicyStore(store).vulStatementsCalendarLoading

const getAnnuityByPolicyId = (store: StoreState, policyId: ?string): ?Annuity => {
  if (!policyId) return null

  const { annuities } = getPolicyStore(store)
  return annuities[policyId]
}

const getAnnuitiesByIds = (store: StoreState, policyIds: Array<string>): Array<Annuity> => {
  const { annuities } = getPolicyStore(store)
  return annuities.filter((ele: Annuity) => policyIds.includes(ele.id))
}

const getPrivatePlacements = (store: StoreState): { [string]: PrivatePlacement } =>
  getPolicyStore(store).privatePlacements

const getPrivatePlacementsShort = (store: StoreState): { [string]: PrivatePlacementShort } =>
  getPolicyStore(store).privatePlacementsShort

const getPrivatePlacementByPolicyId = (store: StoreState, policyId: ?string): ?PrivatePlacement => {
  if (!policyId) return null

  const { privatePlacements } = getPolicyStore(store)
  return privatePlacements[policyId]
}

const getPrivatePlacementsByIds = (
  store: StoreState,
  policyIds: Array<string>,
): Array<PrivatePlacement> => {
  const { privatePlacements } = getPolicyStore(store)
  return policyIds.map((id: string) => privatePlacements[id])
}

const getVULPolicies = (store: StoreState): { [string]: VULPolicy } =>
  getPolicyStore(store).vulPolicies

const getVULPoliciesShort = (store: StoreState): { [string]: VULPolicyShort } =>
  getPolicyStore(store).vulPoliciesShort

const getVULByPolicyId = (store: StoreState, policyId: ?string): ?VULPolicy => {
  if (!policyId) return null

  const { vulPolicies } = getPolicyStore(store)
  return vulPolicies[policyId]
}

const getVULByIds = (store: StoreState, policyIds: Array<string>): Array<PrivatePlacement> => {
  const { privatePlacements } = getPolicyStore(store)
  return policyIds.map((id: string) => privatePlacements[id])
}

const getLifeInsuranceShortPolicies = (
  store: StoreState,
): Array<InsurancePolicyShort | PrivatePlacementShort | VULPolicyShort> => {
  const insurancePoliciesShort = getInsurancePoliciesShort(store)
  const privatePlacementsShort = getPrivatePlacementsShort(store)
  const VULPoliciesShort = getVULPoliciesShort(store)

  // $FlowFixMe
  return [
    ...Object.values(insurancePoliciesShort),
    ...Object.values(privatePlacementsShort),
    ...Object.values(VULPoliciesShort),
  ]
}

const getLifeInsurancePolicies = (
  store: StoreState,
): Array<InsurancePolicy | PrivatePlacement | VULPolicy> => {
  const insurancePolicies = getInsurancePolicies(store)
  const privatePlacements = getPrivatePlacements(store)
  const VULPolicies = getVULPolicies(store)

  // $FlowFixMe
  return [
    ...Object.values(insurancePolicies),
    ...Object.values(privatePlacements),
    ...Object.values(VULPolicies),
  ]
}

const getPPAStatements = (store: StoreState): Array<PPAStatement> =>
  getPolicyStore(store).ppaStatements

const getPPAStatementsShort = (store: StoreState): Array<PPAStatement> =>
  getPolicyStore(store).ppaStatementsShort

const getPPLIStatements = (store: StoreState): Array<PPLIStatement> =>
  getPolicyStore(store).ppliStatements

const getPPLIStatementsShort = (store: StoreState): Array<PPLIStatement> =>
  getPolicyStore(store).ppliStatementsShort

const getVULStatements = (store: StoreState): Array<VULStatement> =>
  getPolicyStore(store).vulStatements

const getVULStatementsShort = (store: StoreState): Array<VULStatement> =>
  getPolicyStore(store).vulStatementsShort

const getPPAMonthStatements = (store: StoreState, month: ?string): Array<PPAStatement> =>
  getPPAStatements(store).filter((statement: PPStatementType) => statement.statementStart === month)

const concatenateStatements = (
  store: StoreState,
  statements: Array<PPStatementType>,
  statementsPendingKey: $Keys<typeof PendingKeys>,
): Array<PPStatementType> => {
  const statementsLoading = api.getPending(store, statementsPendingKey)
  const idfStatementsLoading = api.getPending(store, PendingKeys.getIDFStatementsPending)

  if (statementsLoading || idfStatementsLoading) {
    return []
  }

  const idfStatements = getIDFStatements(store)

  return statements.map((statement: PPStatementType) => {
    const newStatement = cloneDeep(statement)
    newStatement.idfStatements = statement.idfStatements.reduce((acc, curr) => {
      const idfStatement = idfStatements[curr]
      if (idfStatement) {
        acc.push(idfStatement)
      }
      return acc
    }, [])
    return newStatement
  })
}

const getPPAStatementsWithIdfStatements = (store: StoreState): Array<PPAStatement> => {
  const ppaStatements = getPPAStatements(store)

  return concatenateStatements(store, ppaStatements, PendingKeys.getVULStatementsPending)
}

const getPPLIMonthStatements = (store: StoreState, month: ?string): Array<PPLIStatement> =>
  getPPLIStatements(store).filter(
    (statement: PPStatementType) => statement.statementStart === month,
  )

const getPPLIStatementsWithIdfStatements = (store: StoreState): Array<PPLIStatement> => {
  const ppliStatements = getPPLIStatements(store)

  return concatenateStatements(store, ppliStatements, PendingKeys.getVULStatementsPending)
}

const getVULMonthStatements = (store: StoreState, month: ?string): Array<VULStatement> =>
  getVULStatements(store).filter((statement: PPStatementType) => statement.statementStart === month)

const getVULStatementsWithIdfStatements = (store: StoreState): Array<VULStatement> => {
  const vulStatements = getVULStatements(store)

  return concatenateStatements(store, vulStatements, PendingKeys.getVULStatementsPending)
}

const getInsurancePolicyDocuments = (store: StoreState): { [string]: PolicyDocument } =>
  getPolicyStore(store).insurancePolicyDocs

const getAnnuityDocuments = (store: StoreState): { [string]: PolicyDocument } =>
  getPolicyStore(store).annuityDocs

const getDisabilityDocuments = (store: StoreState): { [string]: PolicyDocument } =>
  getPolicyStore(store).disabilityDocs

const getPrivatePlacementDocuments = (store: StoreState): { [string]: PolicyDocument } =>
  getPolicyStore(store).privatePlacementDocs

const getVULPolicyDocuments = (store: StoreState): { [string]: PolicyDocument } =>
  getPolicyStore(store).vulPolicyDocs

const getIdfs = (store: StoreState) => getPolicyStore(store).idfs

const getFootnotesByType = (
  store: StoreState,
  type: $Values<typeof Enums.FootnoteGroupType>,
): Array<FootnoteType> => {
  const { footnoteGroups } = getPolicyStore(store)
  return footnoteGroups[type] || []
}

const getStatementsByEndDate = (
  policyStatements: PPPolicyType,
  startDate: Moment,
  endDate: Moment,
) => {
  const filterByCurrentDates = (statement: *) =>
    // $FlowFixMe
    moment(statement.statementStart).isBetween(startDate, endDate, null, '[)')
  return policyStatements.reduce((acc, cur) => {
    if (filterByCurrentDates(cur)) {
      if (acc[cur.statementEndMonthYear]) {
        acc[cur.statementEndMonthYear].push(cur)
      } else {
        acc[cur.statementEndMonthYear] = [cur]
      }
    }
    return acc
  }, {})
}

const getIDFPdf = (store: StoreState) => getPolicyStore(store).idfPDFAvailabilityImages

const getXirrRates = (store: StoreState): XIRRRate => getPolicyStore(store).xirrRates

const getXirrRatesIdf = (store: StoreState): XIRRRateIDF => getPolicyStore(store).xirrRatesIdf

const getIULMonthlyStatements = (store: StoreState) => getPolicyStore(store).iulMonthlyStatements

const getIULAnnualStatements = (store: StoreState) => getPolicyStore(store).iulAnnualStatements

const getIndexAccountRates = (store: StoreState) => getPolicyStore(store).indexAccountRates

export default {
  getAnnuityPolicies,
  getAnnuityPoliciesShort,
  getDisabilityPoliciesShort,
  getFootnotesByType,
  getInsurancePolicies,
  getInsurancePoliciesShort,
  getInsurancePoliciesCalendar,
  getPrivatePlacementsCalendar,
  getVULStatementsCalendar,
  getInsurancePoliciesCalendarLoading,
  getPrivatePlacementsCalendarLoading,
  getVULStatementsCalendarLoading,
  getPrivatePlacements,
  getPrivatePlacementsShort,
  getPPAStatements,
  getPPAStatementsShort,
  getPPLIStatements,
  getPPLIStatementsShort,
  getVULStatements,
  getVULStatementsShort,
  getAnnuityByPolicyId,
  getAnnuitiesByIds,
  getPolicyByPolicyTypeAndPolicyId,
  getPrivatePlacementByPolicyId,
  getPrivatePlacementsByIds,
  getLifeInsurancePolicies,
  getLifeInsuranceShortPolicies,
  getPPAMonthStatements,
  getPPAStatementsWithIdfStatements,
  getPPLIMonthStatements,
  getPPLIStatementsWithIdfStatements,
  getVULPolicies,
  getVULPoliciesShort,
  getVULMonthStatements,
  getVULStatementsWithIdfStatements,
  getInsurancePolicyDocuments,
  getAnnuityDocuments,
  getPrivatePlacementDocuments,
  getIDFStatements,
  getIdfs,
  getStatementsByEndDate,
  getIDFPdf,
  getVULByPolicyId,
  getVULByIds,
  getVULPolicyDocuments,
  getXirrRates,
  getXirrRatesIdf,
  getIULMonthlyStatements,
  getIULAnnualStatements,
  getIndexAccountRates,
  getGeneralDocuments,
  getDisabilityDocuments,
}
